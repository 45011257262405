import * as $ from "jquery";

$(document).on('turbolinks:load', function () {
  // -----------------------------------------------------------------
  // 共通
  // -----------------------------------------------------------------
  /// Fash Messagr ///
  $(function () {
    setTimeout(function () {
      $('#flashMessageNotice').fadeOut("slow");
    }, 2400);
  });
  $(function () {
    setTimeout(function () {
      $('#flashMessageAlert').fadeOut("slow");
    }, 2400);
  });

  var pcWindow = true;
  var spWindow = false;
  if ($(window).width() < 769) {
    pcWindow = false;
    spWindow = true;
  }
  /*
   * header function
   */
  $('#header .btn-logout').on('click', function (e) {
    e.stopPropagation();
    if (!$(this).hasClass('approved')) {
      e.preventDefault();
      $(this).addClass('approved').next('.caution-balloon').fadeIn();
    }
  });

  $('#header .caution-balloon').on('click', function () {
    $(this).hide().prev('.btn-logout').removeClass('approved');
  });

  $('#header .btn-nav').on('click', function () {
    $(this).toggleClass('opened').next('.btn-list').slideToggle();
  });

  $('body').on('click', function() {
    if ($('.approved')[0]) {
      $('.approved').each(function() {
        $(this).removeClass('approved').next('.caution-balloon').hide();
      });
    }
  });

  // -----------------------------------------------------------------
  // 診断ページ
  // -----------------------------------------------------------------
  // 診断の同意
  if ($("#mypage .top-application")[0]) {
    $('.switch-disabled').on('click', function (e) {
      e.preventDefault();
      $('section').toggleClass('disabled');
      $('body,html').scrollTop(0);
    });
  }
  // -----------------------------------------------------------------
  // 問題回答
  // -----------------------------------------------------------------
  if ($("form.form-inspection")[0]) {
    var $inspections = $('.inspection-area');
    var currentArea = 0;
    var maxCurrent = $('.answer-flow li').length;

    $('label').on('click', function () {
      var $thisParentArea = $(this).closest('.inspection-area');
      var $thisList = $(this).closest('.inspection');
      var inspectionH = $thisList.next('.inspection').outerHeight();
      var radioLen = $thisParentArea.find('input[type="radio"]').length / 4;
      var checkedRadioLen = $thisParentArea.find('input[type="radio"]:checked').length;
      if ($thisList.find('input[type="radio"]:checked').length == 0) checkedRadioLen++;
      if ($thisList.hasClass('bg-notice')) $thisList.removeClass('bg-notice');
      var progressRate = checkedRadioLen / radioLen;
      if (0.75 < progressRate) {
        $('.answer-flow li').eq(currentArea).find('span').eq(2).addClass('after');
      } else if (0.5 < progressRate) {
        $('.answer-flow li').eq(currentArea).find('span').eq(1).addClass('after');
      } else if (0.25 < progressRate) {
        $('.answer-flow li').eq(currentArea).find('span').eq(0).addClass('after');
      }

      var currentWindowPosition = $(window).scrollTop();
      var targetPosition = currentWindowPosition + inspectionH;
      // inspectionH は 一番下の項目だと undefined になる
      if (!isNaN(targetPosition)) {
        $('body, html').animate({"scrollTop": targetPosition}, 300);
      }

    });

    $('.btn-next').on('click', function (e) {
      if (checkRadio() != false) {
        currentArea++;
        switchArea();
        if (currentArea == maxCurrent) $('.progress-area, .caution-box').hide();
      }
    });

    $('.btn-back').on('click', function (e) {
      currentArea--;
      switchArea();
      $('.progress-area, .caution-box').show();
    });

    $("form").submit(function() {
      var self = this;
      $(":submit", self).prop("disabled", true);
      setTimeout(function() {
        $(":submit", self).prop("disabled", false);
      }, 10000);
    });

    function checkRadio() {
      var radioLen = $inspections.eq(currentArea).find('input[type="radio"]').length / 4;
      var checkedRadioLen = $inspections.eq(currentArea).find('input[type="radio"]:checked').length;
      if (radioLen != checkedRadioLen) {
        $inspections.eq(currentArea).find('.inspection').each(function () {
          if ($(this).find('input[type="radio"]:checked').length == 0) {
            $(this).addClass('bg-notice');
          }
        });
        var target = $('.bg-notice').eq(0).offset().top - $('#header').height();
        $('body, html').animate({'scrollTop': target}, 400);
        return false;
      } else {
        return true;
      }
    }

    function switchArea() {
      $inspections.addClass('disabled').eq(currentArea).removeClass('disabled');
      if ($('.answer-flow > li').eq(currentArea - 1).hasClass('now')) {
        $('.answer-flow > li').removeClass('now').eq(currentArea).addClass('now');
        $('.answer-flow > li').eq(currentArea - 1).addClass('after');
      }
      if ($('.answer-flow > li').eq(currentArea + 1).hasClass('now')) {
        $('.answer-flow > li').removeClass('now').eq(currentArea).removeClass('after').addClass('now');
      }
      $('body, html').scrollTop(0);
      $('.comment').text(commentArray[currentArea]);
    }

  }

  // -----------------------------------------------------------------
  // 結果（サンクス）ページ
  // -----------------------------------------------------------------
  if ($("article.result-request ,#mypage")[0]) {
    $('.diagnosis-result .btn-logout').on('click', function (e) {
      e.stopPropagation();
      if (!$(this).hasClass('approved')) {
        e.preventDefault();
        $(this).addClass('approved').next('.caution-balloon').fadeIn();
      }
    });

    $('.diagnosis-result .caution-balloon').on('click', function () {
      $(this).hide().prev('.btn-logout').removeClass('approved');
    });

    $('body').on('click', function() {
      if ($('.approved')[0]) {
        $('.approved').each(function() {
          $(this).removeClass('approved').next('.caution-balloon').hide();
        });
      }
    });
  }

  // -----------------------------------------------------------------
  // ユーザー登録ページ
  // -----------------------------------------------------------------
  if ($('.page-user-register')[0]) {
    var $btnSubmit = $('.check-validate-user-register');

    $('.label-agree').on('click', function () {
      $('#check-agree').prop('checked');
      $('.caution-balloon.agree').hide();
      $btnSubmit.toggleClass('disabled');
    });
  }

  // -----------------------------------------------------------------
  // 結果表示ページ
  // -----------------------------------------------------------------
  if ($("article#pdf-view")[0]) {
    setTimeout(function () {
      $('.modal-creating').fadeOut(800);
    }, 5000);
  }

  // -----------------------------------------------------------------
  // よくある質問ページ
  // -----------------------------------------------------------------
  if ($("#public .question")[0]) {
    $("dt").on('click', function () {
      if ($(this).hasClass('opened')) {
        $(this).removeClass('opened').next("dd").hide();
      } else {
        $("dt").removeClass('opened');
        $("dd").not($(this)).hide();
        $(this).toggleClass('opened').next("dd").slideToggle();
      }
    });

    $('.btn-close').on('click', function () {
      $('dd').slideUp();
    });

    var param = decodeURIComponent(location.search.replace('?q=', ''));
    if (param) {
      var listStr = $('.question-list dt').text();
      if (listStr.indexOf(param) === -1) {
        $('.message-no-result').show();
      }
      $("dt").each(function () {
        var thisStr = $(this).text();
        if (thisStr.indexOf(param) === -1) {
          $(this).hide().next("dd").hide();
        }
      });
    }
  }

  // -----------------------------------------------------------------
  // PDF生成ボタン処理
  // -----------------------------------------------------------------
  if ($('.admin-common')[0]) {
    $('.btn-make-pdf.submit-paper').on('click', function (e) {
      if ($(this).hasClass("submitted")) {
        return false;
      } else {
        $(this).addClass("submitted");
        var agree = window.confirm('ペーパー受検者のPDFを生成します');
        if (agree) {
        } else {
          $(this).removeClass("submitted");
          return false;
        }
      }
    });
    $('.btn-make-pdf.submit-all').on('click', function (e) {
      if ($(this).hasClass("submitted")) {
        return false;
      } else {
        $(this).addClass("submitted");
        var agree = window.confirm('本年全受検者のPDFを生成します');
        if (agree) {
        } else {
          $(this).removeClass("submitted");
          return false;
        }
      }
    });
    $('.btn-make-pdf.submit-all-last-year').on('click', function (e) {
      if ($(this).hasClass("submitted")) {
        return false;
      } else {
        $(this).addClass("submitted");
        var agree = window.confirm('昨年全受検者のPDFを生成します');
        if (agree) {
        } else {
          $(this).removeClass("submitted");
          return false;
        }
      }
    });
  }

  // -----------------------------------------------------------------
  // CSVアップロード処理
  // -----------------------------------------------------------------
  if ($('.csv-up-form-area')[0]) {
    $('.btn-upload').on('click', function (e) {
      if ($('#forms_csv_examination_form_last_year_result').prop('checked')) {
        if (window.confirm('昨年度の受検結果として取り込みを行います。続行しますか？')) {

        } else {
          e.preventDefault();
        }
      }
    });
  }
});